<template>
  <div>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Dados Pessoais
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-form>
          <b-row>
            <b-col lg="5">
              <b-form-group label="Nome do cliente">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.name.$error }"
                v-model="$v.payload.name.$model"
                :state="$v.payload.name.$dirty ?
                !$v.payload.name.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="7">
              <b-form-group label="Email">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.email.$error }"
                v-model="$v.payload.email.$model"
                :state="$v.payload.email.$dirty ?
                !$v.payload.email.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Documento (CPF ou CNPJ)">
                <b-form-input
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                :class="{ 'form-group--error': $v.payload.document.$error }"
                v-model="$v.payload.document.$model"
                :state="$v.payload.document.$dirty ?
                !$v.payload.document.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Celular">
                <b-form-input
                v-mask="'(##) # ####-####'"
                :class="{ 'form-group--error': $v.payload.phone_mobile.$error }"
                v-model="$v.payload.phone_mobile.$model"
                :state="$v.payload.phone_mobile.$dirty ?
                !$v.payload.phone_mobile.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Fone Fixo">
                <b-form-input
                v-mask="'(##)####-####'"
                />
              </b-form-group>
           </b-col>

           <b-col lg="4">
              <b-form-group label="Localidade">
                <b-form-select
                  :options="areaOptions"
                  v-model="payload.localidade"
                />
              </b-form-group>
           </b-col>

           <b-col lg="3">
              <b-form-group label="Unidade Consumidora (Geradora)">
                <b-form-input
                  v-model="payload.uc_generator"
                />
              </b-form-group>
           </b-col>
          </b-row>
        </b-form>
      </div>
    </b-row>

    <b-row class="card mt-5" v-if="id">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-file"></i> Unidades bonificadoras
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row>
          <b-col lg="4">
            <b-form-group label="Registro da unidade">
              <b-form-input v-model="payloadUcs.registration_number"/>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Foto da conta">
              <b-form-file
                accept="image/*"
                placeholder="Selecione uma imagem"
                browse-text="Selecionar"
                v-model="payloadUcs.photo"
              />
            </b-form-group>
          </b-col>

          <b-col lg="4" class="align-btn">
            <b-button variant="primary" @click="addUcs">Adicionar</b-button>
          </b-col>
        </b-row>

        <div class="w-100 mt-3">
          <h5>Unidades cadastradas</h5>
          <b-table
            responsive
            bordered
            :items="ucs"
            :fields="headersTableConsumerUnit"
          >
          <template v-slot:cell(photo)="data">
            <img :src="data.item.photo" class="img-fluid" width="100" alt="">
          </template>
          <template v-slot:cell(actions)="data">
            <b-button variant="danger" @click="confirmeRemoveUc(data.item.id)">
              <i class="fas fa-trash"></i>
              Remover
            </b-button>
          </template>
        </b-table>
        </div>
      </div>
    </b-row>

    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-file"></i> Documentos
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
          <b-col lg="6">
            <div class="w-100 mb-3" v-if="id">
              <img :src="photos.document" class="img-fluid" alt="">
            </div>
            <b-form-group label="Foto (CNH, RG OU CPF)">
              <b-form-file
                accept="image/*"
                placeholder="Selecione uma imagem"
                browse-text="Selecionar"
                v-model="photos.document"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <div class="w-100 mb-3" v-if="id">
              <img :src="photos.energy_account" class="img-fluid" alt="">
            </div>
            <b-form-group label="Foto conta de energia">
              <b-form-file
                accept="image/*"
                placeholder="Selecione uma imagem"
                browse-text="Procurar"
                v-model="photos.energy_account"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-row>

    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-home"></i> Endereço
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
            <b-col lg="3">
              <b-form-group label="CEP">
                <b-form-input
                v-mask="'#####-###'"
                :readonly="address_readonly"
                v-model="payload.address.zip_code"
                />
              </b-form-group>
            </b-col>

            <b-col lg="7">
              <b-form-group label="Rua">
                <b-form-input
                :readonly="address_readonly"
                :class="{ 'form-group--error': $v.payload.address.street.$error }"
                v-model="$v.payload.address.street.$model"
                :state="$v.payload.address.street.$dirty ?
                !$v.payload.address.street.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="2">
              <b-form-group label="Número">
                <b-form-input
                v-model="payload.address.number"
                :readonly="address_readonly"/>
              </b-form-group>
            </b-col>

            <b-col lg="5">
              <b-form-group label="Bairro">
                <b-form-input
                :readonly="address_readonly"
                :class="{ 'form-group--error': $v.payload.address.neighborhood.$error }"
                v-model="$v.payload.address.neighborhood.$model"
                :state="$v.payload.address.neighborhood.$dirty ?
                !$v.payload.address.neighborhood.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="">
              <b-form-group label="Estado">
                <b-form-select
                :class="{ 'form-group--error': $v.payload.address.province.$error }"
                v-model="$v.payload.address.province.$model"
                :state="$v.payload.address.province.$dirty ?
                !$v.payload.address.province.$error : null"
                @change="getCities"
                :options="provinces"/>
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="">
              <b-form-group label="Cidade">
                 <b-form-select
                :class="{ 'form-group--error': $v.payload.address.city_id.$error }"
                v-model="$v.payload.address.city_id.$model"
                :state="$v.payload.address.city_id.$dirty ?
                !$v.payload.address.city_id.$error : null"
                :options="cities"/>
                 <!-- <Autocomplete
                  ref="cities"
                  :source="cities"
                  input-class="form-control"
                  :disableInput="!cities.length"
                  resultsValue="id"
                  :initial-display="city_name"
                  :initial-value="2"
                  :placeholder="labelSelectCities"
                  @selected="setCity"
                /> -->
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
        </b-row>
        <div class="w-100 text-right mt-5">
          <b-button variant="success" @click="save(false)">Salvar</b-button>
          <b-button variant="info" class="ml-3" @click="save(true)">Salvar e criar pré projeto</b-button>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
// import Autocomplete from 'vuejs-auto-complete';
import HelperService from '@/Services/HelperService';
import ClientService from '@/Services/ClientService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  // components: {
  //   Autocomplete,
  // },
  props: ['id'],
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  data() {
    return {
      copy_address: false,
      id_remove: null,
      address_installation_readonly: false,
      address_readonly: false,
      headersTableConsumerUnit: ClientService.getFieldsTableConsumerUnits(),
      provinces: [
        {
          text: 'Selecione',
          value: '',
        },
      ],
      photos: {
        document: '',
        energy_account: '',
      },
      edit_photos: {
        document: '',
        energy_account: '',
      },
      labelSelectCities: 'Selecione primeiro o estado',
      cities: [],
      city_name: 'Teste',
      cities_installation: [],
      payloadUcs: {
        registration_number: '',
        photo: [],
      },
      areaOptions: [
        {
          value: 'Urbano',
          text: 'Urbano',
        },
        {
          value: 'Rural',
          text: 'Rural',
        },
      ],
      ucs: [],
      payload: {
        name: '',
        email: '',
        document: '',
        phone_mobile: '',
        agent_id: null,
        phone: '',
        localidade: 'Urbano',
        uc_generator: '',
        address: {
          zip_code: '',
          street: '',
          number: '',
          neighborhood: '',
          province: '',
          city_id: 3,
          address_type: 'address',
        },
      },
    };
  },
  validations: {
    payload: {
      name: { required },
      email: { required, email },
      document: { required },
      phone_mobile: { required },
      address: {
        street: { required },
        neighborhood: { required },
        province: { required },
        city_id: { required },
      },
    },
  },
  methods: {
    async getProvinces() {
      const { data } = await HelperService.getProvinces();
      data.forEach((item) => {
        this.provinces.push({
          text: item.name,
          value: item.id,
        });
      });
    },

    dataUrlToFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    confirmeRemoveUc(id) {
      this.id_remove = id;
      this.$toast.question(
        'Deseja realmente remover essa unidade consumidora ?',
        'Atenção',
        this.getQuestionObj(this.removeUc, '', 'unidade removida'),
      );
    },

    async removeUc() {
      try {
        await ClientService.deleteUc(this.id_remove);
        const index = this.ucs.findIndex((item) => item.id === this.id_remove);
        this.ucs.splice(index, 1);
      } catch (error) {
        console.log(error);
      }
    },

    async addUcs() {
      const payload = {
        registration_number: this.payloadUcs.registration_number,
        client_id: this.id,
      };

      const formData = new FormData();

      formData.append('file', this.payloadUcs.photo);
      formData.append('payload', JSON.stringify(payload));

      try {
        const { data } = await ClientService.createUc(formData);
        this.ucs.push({
          registration_number: data.registration_number,
          id: data.id,
          photo: data.photo,
        });
      } catch (error) {
        console.log(error);
      }

      this.payloadUcs = {
        registration_number: '',
        photo: null,
      };
    },

    async getClient() {
      const { data } = await ClientService.get(this.id);
      this.payload.id = data.id;
      this.payload.name = data.name;
      this.payload.email = data.email;
      this.payload.document = data.document;
      this.payload.phone_mobile = data.phone_mobile;
      this.payload.phone = data.phone;
      this.payload.localidade = data.localidade;
      this.payload.uc_generator = data.uc_generator;
      this.payload.address.province = data.addresses[0].city.province_id;
      this.payload.address.city_id = data.addresses[0].city.id;
      this.getCities();
      this.city_name = data.addresses[0].city.name;
      this.payload.address.id = data.addresses[0].id;
      this.payload.address.zip_code = data.addresses[0].zip_code;
      this.payload.address.street = data.addresses[0].street;
      this.payload.address.number = data.addresses[0].number;
      this.payload.address.neighborhood = data.addresses[0].neighborhood;
      this.photos.document = data.document_photo;
      this.photos.energy_account = data.energy_account_photo;

      this.ucs = data.consumer_units.map((item) => ({
        registration_number: item.registration_number,
        id: item.id,
        photo: item.photo,
      }));
    },

    async getCities() {
      const params = {
        province_id: this.payload.address.province,
      };

      const { data } = await HelperService.getCities(params);
      this.cities = data.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },

    setCity(city) {
      this.payload.address.city_id = city.selectedObject.id;
    },

    async save(createProject) {
      let { data } = {};
      this.$v.payload.$touch();

      const formData = new FormData();

      formData.append('document', this.photos.document);
      formData.append('energy_account', this.photos.energy_account);
      formData.append('payload', JSON.stringify(this.payload));

      if (!this.$v.payload.$invalid) {
        try {
          if (this.id) {
            data = await ClientService.updateDocs(formData);
            this.messageSuccess(`O cliente ${data.name} foi atualizado com sucesso`);
          } else {
            data = await ClientService.save(formData);
            this.messageSuccess(`O cliente ${data.name} foi cadastrado com sucesso`);
          }

          if (createProject) {
            this.$router.push({ name: 'project-create-with-client', params: { client_id: data.id } });
          } else {
            this.$router.push({ name: 'clients' });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    this.getProvinces();

    if (localStorage.getItem('permission') !== 'admin') {
      const user = JSON.parse(localStorage.getItem('user'));
      this.payload.agent_id = user.agent.id;
    }

    if (this.id) this.getClient();
  },
};
</script>
